import React from 'react'
import Layout from '../components/layout'

export default ({ pageContext: { page } }) => {
  return (
    <Layout title={page.title}>
      <h1>
        {page.title}
      </h1>
      <article
        dangerouslySetInnerHTML={{__html: page.content}} 
      />
    </Layout>
  )
}
